<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Sicht </template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/gifts')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label"> # </label>

          <input
            class="form-control"
            id="sexType"
            name="sexType"
            :value="$codePadding(body.id)"
            disabled
          />
        </div>
      </div>
      <div class="col-md-1">
        <div class="mb-3">
          <label for="sexType" class="form-label">HERR/FRAU</label>

          <input
            class="form-control"
            id="sexType"
            name="sexType"
            :value="body?.usersId?.sexType"
            disabled
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="fname" class="form-label"> Sender's Name </label>

          <input
            class="form-control"
            id="fname"
            name="fname"
            :value="body?.usersId?.fname + ' ' + body?.usersId?.lname"
            disabled
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="lname" class="form-label"> Reciever's  Name </label>

          <input
            class="form-control"
            id="lname"
            name="lname"
            :value="body.sexType + '/' + body.fname + ' ' + body.lname"
            disabled
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="phone" class="form-label"> Reciever's  Phone </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="body.phone"
            disabled
          />
        </div>
      </div>
      
      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Created At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.createdAt)"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Last Update At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.updatedAt)"
            disabled
          />
        </div>
      </div>
      
      <div class="col-md-3">
        <div class="mb-3">
          <label for="price" class="form-label">
            Preisplan
            <!-- <span style="color:red">
              Per Person
            </span> -->
          </label>
          <input
            class="form-control"
            id="price"
            name="price"
            :value="body.price + ' €'"
            disabled
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="depoist" class="form-label">
            Price Paid
            <!-- <span style="color:red">
              Per Person
            </span> -->
          </label>
          <input
            class="form-control"
            id="depoist"
            name="depoist"
            v-model.number="body.depoist"
            :min="0"
            :max="body.price"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="paymentMethod" class="form-label"> Bay Type </label>

          <input
            class="form-control"
            id="paymentMethod"
            name="paymentMethod"
            :value="body.paymentMethod == 'cash' ? 'Cash' : 'Online'"
            disabled
          />
          <Button
            v-if="body.paymentMethod == 'cash' && body.paymentDone == 0"
            label="Change to Cash"
            class="p-button-warning mr-2 ml-2"
            @click="updateBaytype()"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <label for="paymentDone" class="form-label">
            Bucht Fertig
            <Button
              icon="pi pi-plus"
              class="p-button-info mr-2 ml-2"
              @click="updateDone()"
              v-if="!body.paymentDone"
            />
          </label>

          <input
            class="form-control"
            id="paymentDone"
            name="paymentDone"
            :value="body.paymentDone ? 'Erledigt' : '--'"
            disabled
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="senderemail" class="form-label"> Sender's Email </label>

          <input
            class="form-control"
            id="senderemail"
            name="senderemail"
            :value="body?.usersId?.email"
            disabled
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="phone" class="form-label"> Sender's Telefon </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="body?.usersId?.phone"
            disabled
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="recieverType" class="form-label"> Recieve Type </label>
          <input
            class="form-control"
            id="recieverType"
            v-if="body.recieverType == 'email'"
            name="recieverType"
            value="Email"
            disabled
          />

          <input
            class="form-control"
            id="recieverType"
            v-if="body.recieverType == 'himself'"
            name="recieverType"
            value="Receive by himself"
            disabled
          />
          <input
            class="form-control"
            id="recieverType"
            v-if="body.recieverType == 'post'"
            name="recieverType"
            value="Post"
            disabled
          />
        </div>
      </div>

      <!-- <div class="col-md-9">
        <div class="mb-3">
          <label for="RecieverName" class="form-label"> Reciever's Name </label>

          <input
            class="form-control"
            id="RecieverName"
            name="RecieverName"
            v-model="body.RecieverName"
            disabled
          />
        </div>
      </div> -->

      <div class="col-md-6" v-if="body.recieverType == 'himself'">
        <div class="mb-3">
          <label for="recieveDate" class="form-label"> RecieveDatum</label>

          <input
            class="form-control"
            id="recieveDate"
            name="recieveDate"
            :value="body.recieverType == 'himself' ? body.recieveDate : ''"
            disabled
          />
        </div>
      </div>

      <div class="col-md-6" v-if="body.recieverType == 'himself'">
        <div class="mb-3">
          <label for="recieveTime" class="form-label"> RecieveZeit</label>

          <input
            class="form-control"
            id="recieveTime"
            name="recieveTime"
            v-model="body.recieveTime"
            disabled
          />
        </div>
      </div>

      <!-- <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label"> Notiz</label>
          <textarea
            class="form-control"
            id="note"
            name="note"
            v-model="body.note"
            disabled
          />
        </div>
      </div> -->

      <br />
      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label"> Massage Order</label>
          <!-- <Button
            icon="pi pi-plus"
            class="p-button-info mr-2 ml-2"
            @click="body.adminnote.push({ note: '' })"
          />
          <Button
            icon="pi pi-minus"
            class="p-button-warning"
            @click="body.adminnote.length > 1 ? body.adminnote.pop() : null"
          /> -->

          <div class="row m-2" v-for="(item, k) of list" :key="k">
            <div class="col-md-11">
              <input
                v-if="item.massageId"
                class="form-control"
                id="adminnote"
                name="adminnote"
                :value="
                  '#' +
                  item.id +
                  ' => ' +
                  JSON.parse(item.massageId.name).de +
                  ' @ ' +
                  item.pricesId.minutes +
                  ' Minutes = ' +
                  item.pricesId.price +
                  '€ ' +
                  ' (' +
                  $durationFormat(item.start) +
                  '  ' +
                  $durationFormatFull(item.start, item.end) +
                  ') Angestellte ' +
                  item.adminsId.fullName
                "
                disabled
              />
            </div>
            <div class="col-md-1">
              <Button
                icon="pi pi-eye"
                class="p-button-warning"
                @click="$router.push('/admin/massageorder/edit/' + item.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12 text-center">
        <Button
          label="Bearbeiten"
          icon="pi pi-pencil"
          class="ml-2 p-button-info"
          @click="update()"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        // email: null,
        inhomepage: 0,
        depoist: 0,
        adminnote: [
          {
            note: "",
          },
        ],
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
      list: [],
    };
  },
  methods: {
    updateBaytype() {
      this.$http
        .post(`gifts/update/${this.id}`, {
          baytype: false,
        })
        .then(
          () => {
            this.body.baytype = false;
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    updateDone() {
      this.$http
        .put(`gifts/${this.id}`, {
          paymentDone: true,
        })
        .then(
          () => {
            this.body.bayDone = true;
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    update() {
      this.$http.put(`gifts/${this.id}`, this.body).then(
        () => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Updated Successfully",
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    getUser(list) {
      let str = "";
      if (list && list.length > 0) {
        for (const [i, item] of list) {
          if (i == 0) {
            str = item.name;
          } else {
            str = str + " & " + item.name;
          }
        }
      }
      return str;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.$http.get(`gifts/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );

      this.$http
        .post(`appointments/search?limit=100000000`, {
          giftsId: this.id,
        })
        .then(
          (res) => {
            this.list = res.data.data.items;
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    }
  },
  watch: {
    "body.depoist"(val) {
      if (val > 0) {
        this.$http
          .put(`gifts/${this.id}`, {
            depoist: Number(val),
          })
          .then(
            () => {
              this.$toast.add({
                severity: "success",
                summary: "Done Successfully",
                detail: "Updated Successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      }
    },
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
